import { gsap } from "gsap";
/* TODO: Refactor collapsible menus to allow both always and mobile only */
/* eslint-disable */
class AccordionMenu {
  constructor() {
    if (window.matchMedia("(max-width: 991px)").matches) {
      this.init();
    }
  }

  init() {
    let groups = gsap.utils.toArray(".js-mob-accordion-group");
    let menus = gsap.utils.toArray(".js-mob-accordion-menu");
    let menuToggles = groups.map(createAnimation);
    // let modal = document.querySelector(".mob-accordion-modal");

    // if(modal) {
    // 	var bottomModal = document.createElement("div");
    // 	bottomModal.classList.add('filters-wrapper');
    // 	bottomModal.appendChild(modal);
    // 	document.body.appendChild(bottomModal);
    // 	bottomModal.querySelector('.close-filters').addEventListener('click', function(e) {
    // 		bottomModal.querySelector('.collapsibles-wrapper').classList.remove('active');
    // 	});
    // 	document.querySelector('.mob-filter-btn').addEventListener('click', function(e) {
    // 		bottomModal.querySelector('.collapsibles-wrapper').classList.add('active');
    // 	});
    // }

    menus.forEach((menu, index) => {
      if (!menu.classList.contains("initialized")) {
        menu.addEventListener("click", () => toggleMenu(menu));
        menu.classList.add("initialized");
      }
    });

    function toggleMenu(clickedMenu) {
      menuToggles.forEach((toggleFn) => toggleFn(clickedMenu));
    }

    function createAnimation(element) {
      if (!element.classList.contains("initialized")) {
        element.classList.add("initialized");

        let menu = element.querySelector(".js-mob-accordion-menu");
        let box = element.querySelector(".mob-accordion-content");
        let chevron = element.querySelector(".chevron");
        let collapsibleAnimation;
        let chevronAnimation;
        if (box) {
          gsap.set(box, { height: "auto" });
          collapsibleAnimation = gsap
            .from(box, {
              height: 0,
              duration: 0.5,
              ease: "power1.inOut",
            })
            .reverse();
        }
        if (chevron) {
          gsap.set(chevron, { rotate: 180 });
          chevronAnimation = gsap
            .from(chevron, {
              rotate: 0,
              duration: 0.1,
              ease: "power1.inOut",
            })
            .reverse();
        }

        return function (clickedMenu) {
          if (clickedMenu === menu) {
            if (collapsibleAnimation) {
              collapsibleAnimation.reversed(!collapsibleAnimation.reversed());
            }
            if (chevronAnimation) {
              chevronAnimation.reversed(!chevronAnimation.reversed());
            }
          } else {
            if (collapsibleAnimation) {
              collapsibleAnimation.reverse();
            }
            if (chevronAnimation) {
              chevronAnimation.reverse();
            }
          }
        };
      }
    }
  }
}

export default AccordionMenu;
