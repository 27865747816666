/* eslint-disable class-methods-use-this, func-names */
// import { doc } from "prettier";
import LazyLoad from "vanilla-lazyload";

class LazyLoading {
  constructor() {
    this.init();
  }

  init() {
    this.imgs = document.body.querySelectorAll("noscript");
    this.replaceSources();
    this.removeNoscript();
    this.loadImages();
  }

  // Replace images original sources.
  replaceSources() {
    const images = this.imgs;

    for (let k = 0; k < images.length; k += 1) {
      const noscriptContentToReplace = images[k].textContent;
      const imgObjects = noscriptContentToReplace.match(/<img[^>]*\/?>/g);

      if (imgObjects && imgObjects.length > 0) {
        const imgObject = imgObjects[0];
        let replacedImgObject = imgObject;

        // Replace original src attribute with data-src.
        replacedImgObject = replacedImgObject.replace("src", "data-src");

        // Replace original srcset attribute with data-srcset.
        replacedImgObject = replacedImgObject.replace("srcset", "data-srcset");

        // Replace original sizes attribte with data-sizes.
        replacedImgObject = replacedImgObject.replace("sizes", "data-sizes");

        const replacedNoscriptContent = noscriptContentToReplace.replace(
          imgObject,
          replacedImgObject
        );

        images[k].textContent = replacedNoscriptContent;
      }
    }
  }

  // Remove images noscript wrappers.
  removeNoscript() {
    const images = this.imgs;
    let img;
    let parent;

    for (let i = 0; i < images.length; i += 1) {
      if (images[i].textContent.includes("data-src")) {
        img = document.importNode(
          new DOMParser().parseFromString(images[i].textContent, "text/html")
            .body.firstChild,
          true
        ); /* eslint-disable-line */
        parent = images[i].parentElement;
        parent.replaceChild(img, images[i]);
      }
    }
  }

  loadImages() {
    const getClosest = function (elem, selector) {
      let parentContainer = elem;
      do {
        parentContainer = parentContainer.parentNode;
      } while (
        !parentContainer.matches(selector) &&
        parentContainer !== document.body
      );

      return parentContainer;
    };
    if ("lazyLoad" in window) {
      window.lazyLoad.update();
    } else {
      window.lazyLoad = new LazyLoad({
        elements_selector: ".lazy",
        unobserve_entered: true,
        use_native: false,
        callback_loaded: (el) => {
          const parent = getClosest(el, ".js-animate-lazy");
          // const hasVideo = parent.querySelector("video");

          if (parent) {
            setTimeout(() => {
              parent.classList.add("loaded");
            }, 200);
          }
          const isAutoplayingVideo = window.isMobile
            ? el.classList.contains("js-mobile-autoplay")
            : el.classList.contains("js-autoplay");
          if (isAutoplayingVideo) {
            window.player.playVideo(el);
          }
        },
      });

      // window.lazyLoadVideos = new LazyLoad({
      // 	elements_selector: "video.lazy",
      // 	unobserve_entered: true,
      // 	use_native: false,
      // 	callback_loaded: (el) => {
      // 		const parent = getClosest(el, ".js-animate-lazy");
      // 		if (parent) {
      // 			parent.classList.add("loaded");
      // 		}
      // 	},
      // });
    }
  }
}

export default LazyLoading;
