/* eslint-disable class-methods-use-this, prefer-destructuring, no-undef, no-shadow, no-unused-vars */

class SharePostLinks {
  constructor() {
    this.init();
  }

  init() {
    const shareLinks = document.querySelectorAll(".share-post-link");
    if (shareLinks) {
      shareLinks.forEach((shareLink, index) => {
        shareLink.addEventListener("click", (e) => {
          e.preventDefault();
          const linkToShare = shareLink.getAttribute("data-link");
          this.copyText(linkToShare);
        });
      });
    }
  }

  copyText(textToCopy) {
    const myTemporaryInputElement = document.createElement("input");
    const resultDiv = document.querySelector(".copy-result");
    myTemporaryInputElement.type = "text";
    myTemporaryInputElement.value = textToCopy;

    document.body.appendChild(myTemporaryInputElement);

    myTemporaryInputElement.select();
    document.execCommand("Copy");

    document.body.removeChild(myTemporaryInputElement);
    if (resultDiv) {
      setTimeout(() => {
        resultDiv.classList.add("active");
        setTimeout(() => {
          resultDiv.classList.remove("active");
        }, 1500);
      }, 300);
    }
  }
}
export default SharePostLinks;
