class DisableScroll {
  constructor() {
    this.body = document.body;
  }

  // Call this to disable scroll
  disableScroll() {
    this.body.classList.add("u-block-scroll");
  }

  // Call this to enable scroll
  enableScroll() {
    this.body.classList.remove("u-block-scroll");
  }
}

export default DisableScroll;
