/* eslint-disable prefer-destructuring, class-methods-use-this, no-undef, camelcase, prefer-template, func-names */

import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import Pagination from "./pagination";
import Buttons from "./buttons";
import Grid from "../sections/grid";

class Filters {
  constructor() {
    this.filtersWrapper = document.getElementById("filters");
    this.filtersButtons = document.getElementsByClassName("js-filters");
    this.filtersButtonsAll = document.querySelectorAll(".js-filters");
    this.listToggles = document.getElementsByClassName("js-list-toggle");
    this.modals = document.querySelectorAll(".js-list-content");
    this.filterLabel = "";
    if (this.filtersButtons[0]) {
      this.init();
    }
  }

  init() {
    const self = this;
    const filterPosts = this.filterPosts;
    const removeElementsByClass = this.removeElementsByClass;
    const removeHtmlClass = this.removeAllHtmlClass;
    const modals = this.modals;
    const filtersWrapper = this.filtersWrapper;
    const filters = this.filtersButtonsAll;
    const collapsibles = filtersWrapper.querySelector(".collapsibles-wrapper");
    const orderFilterList = filtersWrapper.querySelectorAll(".js-ordering")[0];

    filtersWrapper
      .querySelector(".close-filters")
      .addEventListener("click", function () {
        if (collapsibles) {
          collapsibles.classList.remove("active");
          document.body.classList.remove("active-filters");
          gsap.to(collapsibles, {
            x: "110%",
            ease: "Power1.inout",
          });
        }
      });

    filtersWrapper
      .querySelector(".open-filters")
      .addEventListener("click", function () {
        if (collapsibles) {
          collapsibles.classList.add("active");
          document.body.classList.add("active-filters");

          gsap.to(collapsibles, {
            x: 0,
            ease: "Power1.inout",
          });
        }
      });

    Array.prototype.forEach.call(this.listToggles, function (listToggle) {
      listToggle.addEventListener("click", function (event) {
        event.preventDefault();
        const currentModal =
          this.closest(".js-filter-wrapper").querySelector(".js-list-content");
        const modalIsActive = currentModal.classList.contains("is-current");
        removeHtmlClass(modals, "is-current");

        if (!modalIsActive) {
          currentModal.classList.add("is-current");
        }
        self.filterLabel = this.querySelector(".js-active-label");
      });
    });

    Array.prototype.forEach.call(this.filtersButtons, function (filterButton) {
      filterButton.addEventListener("click", function (event) {
        event.preventDefault();

        const filterIsActive = this.classList.contains("is-active");
        const isOrdeningFilter = this.classList.contains("js-order");
        const isReset = this.classList.contains("js-reset");
        const activeFilters =
          filtersWrapper.getElementsByClassName("is-active");

        // Default filters dataset
        let taxonomy = "";
        let term = "";
        let orderby = "newest";

        // Global dataset
        const postType = filtersWrapper.dataset.postType;
        const titleLength = filtersWrapper.dataset.titleLength;
        const pagination = filtersWrapper.dataset.pagination;
        const viewAll = filtersWrapper.dataset.viewAll;
        const maxPosts = filtersWrapper.dataset.maxPosts;

        const orderbyLabel = this.querySelector(".js-label")
          ? this.querySelector(".js-label").innerHTML
          : "";

        if (!filterIsActive) {
          if (isReset) {
            removeHtmlClass(filters, "is-active");
            orderFilterList
              .getElementsByClassName("js-order")[0]
              .classList.add("is-active");
          } else if (isOrdeningFilter) {
            removeHtmlClass(filters, "is-active", "js-filter");
            filtersWrapper.setAttribute("data-orderby", this.dataset.orderby);
          } else {
            removeHtmlClass(filters, "is-active", "js-order");
          }

          // Add class to current button
          this.classList.add("is-active");

          // Filters dataset
          Array.prototype.forEach.call(activeFilters, function (filter) {
            taxonomy = filter.dataset.taxonomy
              ? filter.dataset.taxonomy
              : taxonomy;
            term = filter.dataset.term ? filter.dataset.term : term;
            orderby = filter.dataset.orderby ? filter.dataset.orderby : orderby;
          });

          filterPosts(
            postType,
            taxonomy,
            term,
            titleLength,
            pagination,
            viewAll,
            maxPosts,
            this,
            removeElementsByClass,
            removeHtmlClass,
            modals,
            orderby,
            self,
            orderbyLabel,
            isReset
          );
        }
      });
    });
  }

  filterPosts(
    postType,
    taxonomy,
    term,
    titleLength,
    pagination,
    viewAll,
    maxPosts,
    element,
    removeElementsByClass,
    removeHtmlClass,
    modals,
    orderby,
    self,
    orderbyLabel,
    isReset
  ) {
    const request = window.ajaxReq();
    let url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
    const data =
      "action=filter_posts" +
      "&post-type=" +
      postType +
      "&taxonomy=" +
      taxonomy +
      "&term=" +
      term +
      "&title-length=" +
      titleLength +
      "&pagination=" +
      pagination +
      "&view-all=" +
      viewAll +
      "&max-posts=" +
      maxPosts +
      "&orderby=" +
      orderby +
      "&nonce=" +
      scriptsAjaxVariables.nonce;

    request.open("POST", url, true); // set true for async, false for sync request
    request.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );
    request.send(data); // or null, if no parameters are passed

    // On success append data
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        try {
          const menuLabels = document.querySelectorAll(".js-active-label");
          const currentLabel = self.filterLabel;
          const currentLableParent =
            currentLabel.parentNode.parentNode.parentNode.parentNode;

          menuLabels.forEach((menuLabel) => {
            const preventResetLabel = menuLabel.dataset.preventReset
              ? menuLabel.dataset.preventReset
              : "";
            let resetLabel = menuLabel.dataset.reset;

            if (
              (preventResetLabel === "check" && !isReset) ||
              (preventResetLabel === "check" &&
                currentLableParent.classList.contains("sorting") === true)
            ) {
              resetLabel =
                menuLabel.parentNode.parentNode.parentNode.parentNode.getElementsByClassName(
                  "is-active"
                )[0].textContent;
            }

            if (
              isReset ||
              currentLableParent.classList.contains("sorting") === false
            ) {
              menuLabel.innerHTML = resetLabel; // eslint-disable-line
            }
          });

          if (currentLabel && !isReset) {
            currentLabel.innerHTML = orderbyLabel;
          }

          removeHtmlClass(modals, "is-current");

          const grid = element.closest(".grid");
          const gridCards = grid.getElementsByClassName("js-grid-cards")[0];
          // Remove current data
          removeElementsByClass(gridCards, "grid-card");

          // Append data
          gridCards.innerHTML = request.responseText;

          // Reload load more button
          window.pagination = new Pagination(orderby);
          window.buttons = new Buttons();

          // Cards animation
          window.setAnimationScenes(new ScrollMagic.Controller());
          window.lazyLoading.init();
          window.tags.init();

          const grids = document.querySelector(".grid");
          if (grids) {
            document.querySelectorAll(".grid").forEach((el) => {
              window.grid = new Grid(el);
            });
            observer.disconnect();
            window.player.addScrollEvents();
          }
          document
            .getElementById("filters")
            .querySelector(".collapsibles-wrapper")
            .classList.remove("active");
          document.body.classList.remove("active-filters");

          if (
            document.querySelector(".collapsibles-wrapper") &&
            window.matchMedia("(max-width: 991px)").matches
          ) {
            gsap.to(document.querySelector(".collapsibles-wrapper"), {
              x: "110%",
            });
          }
        } catch (error) {
          console.log(error); // eslint-disable-line no-console
        }
      }
    };
  }

  removeElementsByClass(element, className) {
    const elements = element.getElementsByClassName(className);

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  removeAllHtmlClass(elements, className, exceptions = "") {
    elements.forEach((element) => {
      if (element.classList.contains(exceptions) === false) {
        element.classList.remove(className);
      }
    });
  }

  addAllHtmlClass(elements, className) {
    elements.forEach((element) => {
      element.classList.add(className);
    });
  }
}

export default Filters;
