class LanguageSelector {
  constructor() {
    const element = document.querySelector(".header__languages ul");
    if (element) {
      const lis = element.getElementsByTagName("li");
      /* eslint-disable */
      for (let i = 0; i < lis.length; i++) {
        /* eslint-enable */
        if (lis[i].textContent.includes("Es")) {
          if (lis[i].innerHTML.includes("//en.summa")) {
            const cadena = lis[i].innerHTML;
            const subcadena = "//en.";
            const nouText = cadena.replace(subcadena, "//");
            lis[i].innerHTML = nouText;
          }
          if (lis[i].innerHTML.includes("//summa.pt")) {
            const cadena = lis[i].innerHTML;
            const subcadena = ".pt";
            const nouText = cadena.replace(subcadena, ".es");
            lis[i].innerHTML = nouText;
          }
        }
      }
    }
  }
}

export default LanguageSelector;
