/* eslint-disable no-undef, class-methods-use-this, prefer-destructuring, no-param-reassign */

import intlTelInput from "intl-tel-input";

function sendFormToHubspot(
  currentLanguage,
  name,
  email,
  phoneNumber,
  companyName,
  city,
  message,
  newsletterSubscription
) {
  let formId = "b0653bc6-5630-48d2-97eb-e02443266149"; // Spanish form ID
  let communicationsConsent = false;

  if (currentLanguage === "gb") {
    formId = "53b8a213-7174-4da6-b057-10663580d454";
  } else if (currentLanguage === "pt") {
    formId = "ee1c099e-71b5-471e-b255-1c2b4d567d26";
  }

  // Create the new request
  const xhr = new XMLHttpRequest();
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/3318473/${formId}`;

  if (companyName === undefined) {
    companyName = "";
  } else {
    companyName = companyName.value;
  }

  if (phoneNumber === undefined) {
    phoneNumber = "";
  } else {
    phoneNumber = phoneNumber.value;
  }

  if (city === undefined) {
    city = "";
  } else {
    city = city.value;
  }

  if (newsletterSubscription.checked) {
    communicationsConsent = true;
  }

  // Example request JSON:
  const data = {
    fields: [
      {
        objectTypeId: "0-1",
        name: "firstname",
        value: name.value,
      },
      {
        objectTypeId: "0-1",
        name: "email",
        value: email.value,
      },
      {
        objectTypeId: "0-1",
        name: "phone",
        value: phoneNumber,
      },
      {
        objectTypeId: "0-1",
        name: "company",
        value: companyName,
      },
      {
        objectTypeId: "0-1",
        name: "city",
        value: city,
      },
      {
        objectTypeId: "0-1",
        name: "message",
        value: message.value,
      },
      {
        objectTypeId: "0-1",
        name: "deseo_recibir_contenido_de_summa",
        value: communicationsConsent,
      },
    ],
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: "I agree to allow Summa to store and process my personal data.",
        communications: [
          {
            value: communicationsConsent,
            subscriptionTypeId: 999,
            text: "I agree to receive marketing communications from Summa.",
          },
        ],
      },
    },
  };

  const finalData = JSON.stringify(data);

  xhr.open("POST", url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // console.log(xhr.responseText); // Returns a 200 response if the submission is successful.
    } else if (xhr.readyState === 4 && xhr.status === 400) {
      // console.log(xhr.responseText); // Returns a 400 error the submission is rejected.
    } else if (xhr.readyState === 4 && xhr.status === 403) {
      // console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
    } else if (xhr.readyState === 4 && xhr.status === 404) {
      // console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
    }
  };

  // Sends the request
  xhr.send(finalData);
}

class Forms {
  constructor() {
    this.init();
  }

  init() {}

  newForm(form) {
    this.formPrivacyPolicy = document.getElementById("input-form");
    this.formSendButton = document.getElementsByClassName("contact-button")[0];
    this.closeNotificationButton =
      document.getElementById("close-notification");
    // Load Re-captcha V3 or throw an error
    if (googleRecaptchaWebKey) {
      // eslint-disable-line
      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${googleRecaptchaWebKey}`
      ); // eslint-disable-line
      document.getElementsByTagName("head")[0].appendChild(script);

      // Load international telephone input
      const input = document.querySelector("#phone");
      const countryData = window.intlTelInputGlobals.getCountryData();
      let currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line

      if (countryData.length > 0) {
        for (let i = 0; i < countryData.length; i += 1) {
          const country = countryData[i];
          country.name = country.name.replace(/.+\((.+)\)/, "$1");
        }
      }

      if (currentLanguage === "en") {
        currentLanguage = "gb";
      } else if (currentLanguage === "pt-pt") {
        currentLanguage = "pt";
      }

      intlTelInput(input, {
        // Any initialisation options go here. Check https://github.com/jackocnr/intl-tel-input
        separateDialCode: "true",
        utilsScript: `https://${window.location.hostname}/wp-content/themes/summa/js/vendor/utils.js`,
        // autoPlaceholder: "aggressive",
        autoPlaceholder: "polite",
        initialCountry: `${currentLanguage}`,
        preferredCountries: [""],
      });

      // Make sure the privacy policy value is unchecked
      this.formPrivacyPolicy.checked = false;
      this.formPrivacyPolicy.value = "unchecked";

      // Listen for privacy policy checkbox changes
      this.formPrivacyPolicy.addEventListener(
        "change",
        this.privacyPolicyCheckboxToogle
      );

      // Prevent form submit
      form.addEventListener("submit", this.preventFormSubmit);

      // Form validations and submit
      this.formSendButton.addEventListener("click", this.submitValidateForm);

      // Close notificacion banner
      this.closeNotificationButton.addEventListener(
        "click",
        this.closeNotificationBanner
      );

      // Enable submit button
      this.formSendButton.disabled = false;
    } else {
      console.error("Set up the Google ReCaptcha V3 keys"); // eslint-disable-line no-console
    }
  }

  privacyPolicyCheckboxToogle(privacyPolicyCheckbox) {
    if (privacyPolicyCheckbox.srcElement.value === "unchecked") {
      privacyPolicyCheckbox.srcElement.value = "checked";
    } else {
      privacyPolicyCheckbox.srcElement.value = "unchecked";
    }
  }

  preventFormSubmit(evt) {
    evt.preventDefault();
  }

  submitValidateForm(evt) {
    // Get the form parameters
    const action = "contact_form";
    const name = this.form.querySelectorAll("[name=name]")[0];
    const email = this.form.querySelectorAll("[name=email]")[0];
    const position = this.form.querySelectorAll("[name=position]")[0];
    const phoneNumber = this.form.querySelectorAll("[name=phone_number]")[0];
    const city = this.form.querySelectorAll("[name=city]")[0];
    const companyName = this.form.querySelectorAll("[name=company_name]")[0];
    const employees = this.form.querySelectorAll("[name=company_employees]")[0];
    const file = this.form.querySelectorAll("[name=attachment_file]")[0];
    const message = this.form.querySelectorAll("[name=message]")[0];
    const messageIsRequired = this.form.querySelectorAll(
      "[name=user_message_required]"
    )[0];
    const privacyPolicy = this.form.querySelectorAll(
      "[name=privacy_policy]"
    )[0];
    const newsletterSubscription = this.form.getElementsByClassName(
      "newsletter_subscription"
    )[0];
    const successMessage = this.form.querySelectorAll(
      "[name=success_message]"
    )[0];
    const errorMessage = this.form.querySelectorAll("[name=error_message]")[0];
    const notificationsBanner = document.getElementById("notification-banner");
    const notificationMessage =
      document.getElementsByClassName("notification-text")[0];
    const currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line
    const defaultLanguage = encodeURI(scriptsAjaxVariables.default_language); // eslint-disable-line
    const formData = new FormData();
    formData.append("action", action);

    if (name) {
      formData.append("name", name.value);
    }

    if (email) {
      formData.append("email", email.value);
    }

    if (position) {
      formData.append("position", position.value);
    }

    if (phoneNumber) {
      formData.append("phone_number", phoneNumber.value);
    }

    if (city) {
      formData.append("city", city.value);
    }

    if (companyName) {
      formData.append("company_name", companyName.value);
    }

    if (employees) {
      formData.append("company_employees", employees.value);
    }

    if (file) {
      formData.append("attachment_file", file.files[0]);
    }

    if (message) {
      formData.append("message", message.value);
    }

    if (messageIsRequired) {
      formData.append("user_message_required", messageIsRequired.value);
    }

    if (privacyPolicy) {
      formData.append("privacy_policy", privacyPolicy.value);
    }

    if (currentLanguage) {
      formData.append("current_language", currentLanguage);
    }

    if (defaultLanguage) {
      formData.append("default_language", defaultLanguage);
    }

    // Disable and animate the submit button
    evt.srcElement.disabled = true;
    evt.srcElement.classList.add("validating");

    // Recaptcha validation
    grecaptcha.ready(() => {
      // eslint-disable-line
      grecaptcha.execute(googleRecaptchaWebKey, { action }).then((token) => {
        // eslint-disable-line
        if (token) {
          formData.append("token", token);
        }

        // Ajax form submit
        const xmlhttp = window.ajaxReq();
        const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
        xmlhttp.open("POST", url, true); // set true for async, false for sync request
        xmlhttp.send(formData); // or null, if no parameters are passed

        // Validation
        xmlhttp.onreadystatechange = () => {
          if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
            try {
              const data = JSON.parse(xmlhttp.responseText);

              // Hide previous errors
              name.classList.remove("show-error");
              email.classList.remove("show-error");
              privacyPolicy.classList.remove("show-error");

              if (position) {
                position.classList.remove("show-error");
              }

              if (phoneNumber) {
                phoneNumber.classList.remove("show-error");
              }

              if (message) {
                message.classList.remove("show-error");
              }

              if (file) {
                file.classList.remove("show-error");
              }

              if (data.validation === false) {
                // Show errors
                if (data.name) {
                  name.classList.add("show-error");
                }
                if (data.email) {
                  email.classList.add("show-error");
                }
                if (data.position) {
                  position.classList.add("show-error");
                }
                if (data.phone_number) {
                  phoneNumber.classList.add("show-error");
                }
                if (data.message) {
                  message.classList.add("show-error");
                }
                if (data.file) {
                  file.classList.add("show-error");
                }
                if (data.privacy_policy) {
                  privacyPolicy.classList.add("show-error");
                }

                // Scroll to the first error.
                const elementsWithError =
                  evt.srcElement.form.getElementsByClassName("show-error");
                elementsWithError[0].scrollIntoView({
                  block: "center",
                  behavior: "smooth",
                });
              } else if (data.success === false) {
                // Error notification message
                notificationMessage.innerHTML = errorMessage.value;

                // Show notification
                notificationsBanner.classList.add("active");
              } else {
                // Success notification message
                notificationMessage.innerHTML = successMessage.value;

                // Send form to Hubspot
                sendFormToHubspot(
                  currentLanguage,
                  name,
                  email,
                  phoneNumber,
                  companyName,
                  city,
                  message,
                  newsletterSubscription
                );

                // Clear the form
                evt.srcElement.form.reset();

                // Show notification
                notificationsBanner.classList.add("active");
              }

              // Enable and stop the submit button animation
              evt.srcElement.disabled = false;
              evt.srcElement.classList.remove("validating");
            } catch (error) {
              // Show notification
              notificationsBanner.classList.add("active");
            }
          }
        };
      });
    });
  }

  closeNotificationBanner(evt) {
    evt.preventDefault();
    evt.srcElement.parentNode.classList.remove("active");
  }
}

export default Forms;
