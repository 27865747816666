import { gsap } from "gsap";

class Buttons {
  constructor() {
    const buttons = document.querySelectorAll(".js-button");

    if (buttons && window.matchMedia("(min-width: 992px)").matches) {
      // const listItems = button.querySelectorAll(".js-list-item");
      // if (listItems) {
      buttons.forEach((el) => {
        const buttonMasker = document.createElement("span");
        buttonMasker.classList.add("masker");
        buttonMasker.classList.add("js-masker");
        el.append(buttonMasker);

        const buttonLabel = document.createElement("span");
        buttonLabel.classList.add("title");
        buttonLabel.classList.add("js-title");
        if (el.querySelector(".no-js-label")) {
          buttonLabel.innerHTML = el.querySelector(".no-js-label").textContent;
          el.append(buttonLabel);
        }

        const maskers = el.querySelectorAll(".js-masker");
        const texts = el.querySelectorAll(".js-title");
        if (maskers && texts) {
          gsap.set(maskers, { scaleY: 0.02, transformOrigin: "left bottom" });
          gsap.set(texts, { x: 0 });
        }
        const animDuration = document.body.getAttribute("data-buttons-speed");
        const rawPower = document.body.getAttribute("data-buttons-power");
        const rawBalance = document.body.getAttribute("data-buttons-balance");

        let animPower;
        let animBalance;

        switch (rawPower) {
          case "low":
            animPower = "power1";
            break;
          case "medium":
            animPower = "power2";
            break;
          case "high":
            animPower = "power3";
            break;
          case "higher":
            animPower = "power4";
            break;
          default:
            animPower = "power1";
            break;
        }

        switch (rawBalance) {
          case "in":
            animBalance = "in";
            break;
          case "out":
            animBalance = "out";
            break;
          case "inout":
            animBalance = "inOut";
            break;
          default:
            animBalance = "inOut";
            break;
        }

        const scaleEasing = animPower + "." + animBalance; // eslint-disable-line
        const currentButton = gsap.utils.selector(el);

        const currentMasker = currentButton(".js-masker");

        const buttontItemTl = gsap
          .timeline({
            paused: true,
          })
          .to(
            currentMasker,
            {
              scaleY: 1,
              ease: scaleEasing,
              duration: animDuration,
            },
            0
          );

        el.addEventListener("mouseenter", function playCurrentTimeline() {
          buttontItemTl.restart();
        });

        el.addEventListener("mouseleave", function resetCurrentTimeline() {
          buttontItemTl.reverse();
        });
      });
      // }
    }
  }
}

export default Buttons;
