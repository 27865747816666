/* eslint-disable */
import barba from "@barba/core";
import ScrollMagic from "scrollmagic";
import { gsap } from "gsap";

class Barba {
  constructor() {
    this.initBarba();
  }

  initBarba() {
    const self = this;
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    const barbaContainer = document.querySelector(".barba-container");
    self.inSpeed = barbaContainer.getAttribute("data-in-speed");
    self.outSpeed = barbaContainer.getAttribute("data-out-speed");
    barba.init({
      debug: false,
      cacheIgnore: false,
      prefetchIgnore: false,
      sync: true,
      timeout: 5000,
      transitions: [
        {
          name: "default",
          async leave({ current, next, trigger }) {
            const done = this.async();
            self.outAnimation(current.container, done);
            const toDelay = self.outSpeed * 1000;
            await self.delay(toDelay);
            done();
          },
          async enter({ current, next, trigger }) {
            self.inAnimation(next.container);
            document.body.classList.remove("u-mobile-block-scroll");
          },
        },
      ],
      prevent: ({ el }) => {
        const ignoreClasses = ["ab-item", "another-class-here"]; // Additional (besides .js-barba-prevent) ignore links with these classes (ab-item is for wp admin toolbar links)
        if (/.pdf/.test(el.href.toLowerCase())) {
          return true;
        }
        if (el.classList && el.classList.contains("js-barba-prevent")) {
          return true;
        }
        if (el.dataset && el.dataset.lightbox) {
          return true;
        }
        for (let i = 0; i < ignoreClasses.length; i += 1) {
          if (el.classList.contains(ignoreClasses[i])) {
            return true;
          }
        }
      },
      requestError: (trigger, action, url, response) => {
        console.log("REQUEST ERROR"); // eslint-disable-line no-console
        console.log(url); // eslint-disable-line no-console
        console.log(response); // eslint-disable-line no-console
      },
    });

    barba.hooks.beforeEnter(({ current, next, trigger }) => {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
      if (document.body.classList.contains("u-mobile-block-scroll")) {
        window.menuReset();
      }
    });

    barba.hooks.enter(({ current, next, trigger }) => {
      window.requestAnimationFrame(() => {
        this.setBodyClasses(next.container);
        this.setTranslationLinks(next.container);
        this.mobileMenu();
        this.updateCurrentHighlightedMenu(next.html);

        // Modules
        window.modulesLoader.loadModules();

        // Trigger location change event
        window.dispatchEvent(new Event("locationchange"));

        // Animations
        setTimeout(() => {
          window.setAnimationScenes(new ScrollMagic.Controller());
        }, 300);

        // Google Analytics
        if (typeof window.ga === "function") {
          window.ga("send", "pageview", window.location.pathname);
        }

        if (document.querySelector("body").classList.contains("single-works")) {
          document.querySelector("body").classList.add("no-referrer");
        }

        // WP admin bar
        this.fixWpAdminBar();
        // initialize Videos on the page
        const contentWrapper = document.querySelector(".content-wrapper");
        // initialize Plyr videos (custom player: no autoplay, controls, etc.)
        // window.player.initPlyrs(contentWrapper);
        // initialize add video play/pause on scroll
        window.player.addScrollEvents(contentWrapper);
      });
    });
  }

  setBodyClasses(e) {
    const skipClasses = [""]; // Add the classes you want to skip
    const currentBodyClasses = document.querySelector("body").classList;
    const newBodyClasses = e.querySelector("#body-classes").value;
    const splittedNewBodyClasses = newBodyClasses.split(" ");
    for (let i = 0; i < currentBodyClasses.length; i += 1) {
      if (newBodyClasses.indexOf(currentBodyClasses[i]) > -1) {
        skipClasses.push(currentBodyClasses[i]);
      }
    }
    Array.from(currentBodyClasses).forEach((bodyClass) => {
      // Don't remove class if it exists in skipClasses
      if (!skipClasses.includes(bodyClass)) {
        currentBodyClasses.remove(bodyClass);
      }
    });
    for (let s = 0; s < splittedNewBodyClasses.length; s += 1) {
      document.querySelector("body").classList.add(splittedNewBodyClasses[s]);
    }
  }

  setTranslationLinks(e) {
    "use strict";

    const linksData = e.getAttribute("data-translation-links");
    if (linksData) {
      const links = JSON.parse(linksData);
      if (links.constructor === Array && links.length > 0) {
        for (let s = 0; s < links.length; s += 1) {
          const li = document.querySelector(
            `.header__languages ul li:nth-child( ${s + 1}`
          );
          if (li !== null) {
            li.setAttribute("href", links[s]);
          }
        }
      }
    }
  }

  delay(n) {
    const amount = n || 2000;
    return new Promise((done) => {
      setTimeout(() => {
        done();
      }, amount);
    });
  }

  mobileMenu() {
    const mobileMenuToogler = document.getElementById("main-menu-toggler");
    if (mobileMenuToogler) {
      mobileMenuToogler.checked = false;
    }
  }

  /**
   * Parses the DOM structure of the BarbaJS fetch, and applies same classes on the header in DOM.
   * @param {String} htmlString - The new page html in string format from barbaJs
   */
  updateCurrentHighlightedMenu(htmlString) {
    const html = new window.DOMParser().parseFromString(
      htmlString,
      "text/html"
    );
    if ("body" in html === false || html.body.childNodes.length < 1) {
      // Why the length check?
      return;
    }
    // First remove current classes from all menu items and sub menus.
    for (const el of Array.from(document.querySelectorAll(".menu-item"))) {
      el.classList.remove("current-menu-item");
      el.classList.remove("current-menu-parent");
      el.classList.remove("current-menu-ancestor");
    }
    // Get the new current menu items and parents and set the appropiate classes.
    let newEl;
    for (const el of Array.from(html.querySelectorAll(".current-menu-item"))) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`);
        if (newEl !== null) {
          newEl.classList.add("current-menu-item");
        }
      }
    }
    for (const el of Array.from(
      html.querySelectorAll(".current-menu-parent")
    )) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`);
        if (newEl !== null) {
          newEl.classList.add("current-menu-parent");
        }
      }
    }
    for (const el of Array.from(
      html.querySelectorAll(".current-menu-ancestor")
    )) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`);
        if (newEl !== null) {
          newEl.classList.add("current-menu-ancestor");
        }
      }
    }
  }

  inAnimation(container) {
    gsap.fromTo(
      container,
      { opacity: 0 },
      {
        opacity: 1,
        duration: this.inSpeed,
        ease: "linear",
      }
    );
    // });
  }

  outAnimation(container) {
    gsap.fromTo(
      container,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: this.outSpeed,
        ease: "linear",
      }
    );
  }

  fixWpAdminBar() {
    if (!document.querySelector("#wpadminbar")) {
      return;
    }

    const xmlhttp = ajaxReq(); // eslint-disable-line
    const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
    const params = `action=sm_fix_barbajs_wp_admin&location=${window.location.href}`;

    xmlhttp.open("POST", url, true); // set true for async, false for sync request
    xmlhttp.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );
    xmlhttp.send(params); // or null, if no parameters are passed
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        try {
          const response = JSON.parse(xmlhttp.responseText);
          if (response && response.success === true) {
            const adminMenuLinks = Array.from(
              document.querySelector(".ab-top-menu").querySelectorAll("a")
            );
            for (const adminLink of adminMenuLinks) {
              const mregex = /post=(\d+)&/g;
              adminLink.classList.add("js-barba-prevent");
              const href = adminLink.getAttribute("href");
              const groups = mregex.exec(href);
              if (groups && groups.length >= 2) {
                adminLink.setAttribute(
                  "href",
                  href.replace(groups[1], response.pageId)
                );
              } else {
                const chunks = href.split("?url=");
                const encodedWindowLocationHref = encodeURIComponent(
                  window.location.href
                );
                if (chunks.length === 2) {
                  adminLink.setAttribute(
                    "href",
                    `${chunks[0]}?url=${encodedWindowLocationHref}`
                  );
                }
              }
            }
          }
        } catch (error) {
          // console.log(error);
        }
      }
    };
  }
}

export default Barba;
