class Lottie {
  constructor() {
    this.animatedElementsContainers =
      document.getElementsByClassName("js-lottie");
    if (this.animatedElementsContainers) {
      this.init();
    }
  }

  init() {
    Array.prototype.forEach.call(
      this.animatedElementsContainers,
      (animatedElementContainer) => {
        const animationPath = animatedElementContainer.getAttribute(
          "data-animation-path"
        );
        if (animationPath !== undefined && animationPath !== null) {
          if (window.lottie === undefined) {
            window.loadJS(
              "/wp-content/themes/summa/js/vendor/lottie.js",
              () => {
                window.lottie.loadAnimation({
                  container: animatedElementContainer, // the dom element that will contain the animation
                  renderer: "svg",
                  loop: true,
                  autoplay: true,
                  path: animationPath, // the path to the animation json
                  rendererSettings: {
                    scaleMode: "noScale",
                  },
                });
              }
            );
          } else {
            setTimeout(() => {
              this.animate(this.animatedElementContainer, animationPath);
            }, 1000);
          }
        }
      }
    );
  }
}

export default Lottie;
