/* eslint-disable */

// Import libraries
import LazyLoading from "./lib/lazy-loading";
import Lottie from "./lib/lottie";

// Import components
import Cta from "./template-parts/components/cta";
import Tags from "./template-parts/components/tags";
import Filters from "./template-parts/components/filters";
import Pagination from "./template-parts/components/pagination";
import AccordionMenu from "./template-parts/components/accordion-menu";
import SharePostLinks from "./template-parts/components/share-post-links";
import Buttons from "./template-parts/components/buttons";

// Import sections
import AI from "./template-parts/sections/ai";
import ContactForm from "./template-parts/sections/contact-form";
import SliderGallery from "./template-parts/sections/slider-gallery";
import FeaturedList from "./template-parts/sections/featured-list";
import Grid from "./template-parts/sections/grid";
import Player from "./template-parts/sections/player";
import ModalHubspot from "./template-parts/sections/modal-hubspot";
import LanguageSelector from "./template-parts/sections/language-selector";

export default class ModulesLoader {
  constructor(el = document) {
    this.el = el;
  }

  loadModules() {
    // Load libraries
    window.previousUrl = null;
    window.lazyLoading = new LazyLoading();

    window.lottie = new Lottie();

    // Load components
    window.cta = new Cta();
    window.tags = new Tags();
    window.filters = new Filters();
    window.pagination = new Pagination();
    window.accordionMenu = new AccordionMenu();
    window.sharePostLinks = new SharePostLinks();

    // Load sections
    window.ai = new AI();
    window.contactForm = new ContactForm();
    window.sliderGallery = new SliderGallery();
    window.modalHubspot = new ModalHubspot();
    window.LanguageSelector = new LanguageSelector();

    // Video Player
    window.player = new Player();

    window.buttons = new Buttons();

    // Featured List
    document.querySelectorAll(".featured-list").forEach((el) => {
      window.featuredList = new FeaturedList(el);
    });

    // Featured List
    document.querySelectorAll(".grid").forEach((el) => {
      window.grid = new Grid(el);
    });
  }
}
