/* eslint-disable */
import "./scss/style.scss";
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import Header from "./js/template-parts/common/header";
import ModulesLoader from "./js/modules-loader";
import Barba from "./js/lib/barba";
import Cookies from "./js/template-parts/popups/cookies";
import DisableScroll from "./js/lib/disable-scroll";

window.scrollTo({
  top: 0,
  behavior: "instant",
});

if (document.querySelector("body").classList.contains("single")) {
  document.querySelector("body").classList.add("no-referrer");
}

window.isSafari =
  navigator.vendor.match(/apple/i) &&
  !navigator.userAgent.match(/crios/i) &&
  !navigator.userAgent.match(/fxios/i) &&
  !navigator.userAgent.match(/Opera|OPT\//);

if (window.isSafari) {
  // Safari browser not used
  document.body.classList.add("is-safari");
}
const userAgent = navigator.userAgent.toLowerCase();

function isMobile() {
  const isMobileAgent =
    /Android.+Mobile|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  return (
    ("ontouchstart" in window && isMobileAgent) ||
    (navigator.maxTouchPoints && isMobileAgent) ||
    window.matchMedia("(max-width: 991px)").matches
  );
}

function isTablet() {
  const isTabletAgent =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  return (
    ("ontouchstart" in window && isTabletAgent) ||
    (navigator.maxTouchPoints && isTabletAgent) ||
    window.matchMedia("(max-width: 1025px)").matches
  );
}

function isMobileOrTablet() {
  return isMobile() || isTablet();
}

window.isMobile = isMobile();
window.isTablet = isTablet();
window.isMobileOrTablet = isMobileOrTablet();

const modal = document.getElementById("modal-project");
const contentWrapper = document.querySelector(".content-wrapper");

function on(elSelector, eventName, selector, fn) {
  const element = document.querySelector(elSelector);
  element.addEventListener(eventName, function (event) {
    const possibleTargets = element.querySelectorAll(selector);
    const target = event.target;

    for (let i = 0, l = possibleTargets.length; i < l; i++) {
      let el = target;
      const p = possibleTargets[i];

      while (el && el !== element) {
        if (el === p) {
          return fn.call(p, event);
        }

        el = el.parentNode;
      }
    }
  });
}

window.onEvent = on;

window.onEvent("body", "click", ".js-modal-back", function (e) {
  if (
    !document.body.classList.contains("no-referrer") &&
    window.loading === false
  ) {
    e.preventDefault();
    // this function is only called, when a list item with 'js-modal-back' class is called
    if (window.previousUrl) {
      window.history.pushState(window.state, "", window.previousUrl);
      window.grid.popRender(window.state);
    } else {
      // go back in history (it triggers the popstate event and animations)
      window.history.back();
    }
    window.player.autoPlayVideos(contentWrapper);
    window.player.pauseVideos(modal);
  }
});

window.onEvent("body", "click", ".js-project-link", function (e) {
  if (
    !document.body.classList.contains("no-referrer") &&
    window.loading === false
  ) {
    window.loading = true;
    e.preventDefault();
    if (document.body.classList.contains("modal-project-open")) {
      gsap.to("#modal-project", {
        yPercent: 100,
        ease: "Power2.easeInOut",
        onComplete: () => {
          window.state.modal = true;
          window.grid.projectModal.scrollTop = 0;
          window.grid.loadProject(this, window.grid.projectModal, window.grid);
        },
      });
    } else {
      window.grid.projectModal.scrollTop = 0;
      window.grid.loadProject(this, window.grid.projectModal, window.grid);
    }
  }
});

// Load objects

// Header
window.header = new Header();

// BarbaJS
window.barba = new Barba();

window.addEventListener("load", () => {
  // Ajax requests
  window.ajaxReq = function ajaxReq() {
    if (window.XMLHttpRequest) {
      return new XMLHttpRequest(); // eslint-disable-line
    }

    if (window.ActiveXObject) {
      return new ActiveXObject("Microsoft.XMLHTTP"); // eslint-disable-line no-console
    }

    console.log("Browser does not support XMLHTTP."); // eslint-disable-line no-console
    return false;
  };

  // Set animations
  window.setAnimationScenes = function setAnimationScenes(
    scrollMagicController
  ) {
    const animatableElements = document.querySelectorAll(".js-animate");
    animatableElements.forEach(function (el) {
      new ScrollMagic.Scene({
        triggerElement: el,
        triggerHook: 1,
        reverse: false,
      })
        .setClassToggle(el, "animated")
        .addTo(scrollMagicController);
    });
  };

  // Load JS scripts
  window.loadJS = function loadJS(file, callback) {
    if (document.body.querySelector(`script[src*="${file}"]`) != null) {
      callback();
      return;
    }

    // DOM: Create the script element
    const jsElm = document.createElement("script");
    // set the type attribute
    jsElm.type = "text/javascript";
    // make the script element load file
    jsElm.src = file;
    // add a callback
    jsElm.addEventListener("load", callback);
    // finally insert the element to the body element in order to load the script
    document.body.appendChild(jsElm);
  };

  window.loading = false;

  // Modules loader
  window.modulesLoader = new ModulesLoader();
  window.modulesLoader.loadModules();

  // Disable scroll
  window.disableScroll = new DisableScroll();

  // Cookies banner
  window.cookies = new Cookies();

  // initialize Plyr videos (custom player: no autoplay, controls, etc.)
  window.player.initPlyrs(contentWrapper);

  // initialize add video play/pause on scroll
  window.player.addScrollEvents(contentWrapper);

  window.player.autoPlayVideos(contentWrapper);

  // Animations
  window.setAnimationScenes(new ScrollMagic.Controller());
});
