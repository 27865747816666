import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import SliderGallery from "./slider-gallery";

/* eslint-disable */
class Grid {
  constructor(grid) {
    this.grid = grid;
    if (this.grid) {
      this.init();
    }
    const barbaContainer = document.querySelector(".barba-container");
    const projectModal = document.getElementById("modal-project");
    const backgroundContent = document.querySelector(".content-wrapper");
    this.projectModal = projectModal;
    this.backgroundContent = backgroundContent;
    this.modalAnimationDuration =
      barbaContainer.getAttribute("data-modal-speed");
  }

  init() {
    window.state = { modal: false };
    const grid = this.grid;
    let self = this;

    if (grid.querySelector(".wrapper")) {
      const wrappers = grid.querySelectorAll(".col-group");
      wrappers.forEach((wrapper) => {
        if (wrapper.querySelector(".highlighted-grid-card")) {
          wrapper.classList.toggle("highlighted-wrapper");
        }
      });
    }

    if (!window.isMobileOrTablet) {
      const videosToPlay = grid.querySelectorAll(".js-to-play");
      if (videosToPlay) {
        videosToPlay.forEach((el) => {
          el.addEventListener("mouseenter", function playCurrentTimeline(e) {
            e.target.currentTime = 0;
            window.player.playVideo(e.target);
          });

          el.addEventListener("mouseleave", function resetCurrentTimeline(e) {
            window.player.pauseVideo(e.target);
          });
        });
      }
    }
    const projectLinks = document.getElementsByClassName("js-project-link");
    Array.prototype.forEach.call(projectLinks, function (projectLink) {
      projectLink.addEventListener("click", function (event) {
        if (window.loading === false) {
          window.previousUrl = window.location.href;
        }
      });
    });
  }

  popRender() {
    if (true == window.state.modal) {
      document.body.classList.remove("modal-project-open");
      gsap.to("#modal-project", {
        yPercent: 100,
        ease: "Power2.easeInOut",
        onComplete: function () {
          gsap.set("#modal-project", { autoAlpha: 0 });
          document.getElementById("modal-project").innerHTML = "";
          window.state.modal = false;
        },
      });
    }
  }

  preventAjaxForModalLinks(projectModal) {
    const parseableSections = projectModal.querySelectorAll(
      ".js-modal-prevent-links"
    );
    if (parseableSections) {
      parseableSections.forEach(function (el) {
        const parseableLinks = el.querySelectorAll("a");
        parseableLinks.forEach(function (link) {
          link.classList.add("js-barba-prevent");
        });
      });
    }
  }

  loadProject(el, projectModal, self) {
    const id = el.getAttribute("data-project-id");
    const nextURL = el.getAttribute("href");
    const nextTitle = el.getAttribute("data-title");
    const request = window.ajaxReq();
    const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
    const data =
      "action=get_project_by_id" +
      "&id=" +
      id +
      "&nonce=" +
      scriptsAjaxVariables.nonce;

    // AJAX request
    request.open("POST", url, true); // set true for async, false for sync request
    request.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );
    request.send(data); // or null, if no parameters are passed

    gsap.set("#modal-project", {
      yPercent: 100,
      autoAlpha: 0,
    });
    // On success append data
    request.onreadystatechange = function () {
      if (request.readyState === 4 && request.status === 200) {
        try {
          // pause background videos

          window.player.pauseVideos(self.backgroundContent);

          window.state.modal = true;

          if (!el.classList.contains("js-project-navigation")) {
            window.history.pushState(window.state, nextTitle, nextURL);
          } else {
            window.history.replaceState(window.state, nextTitle, nextURL);
          }
          window.addEventListener("popstate", function () {
            self.popRender(); // See example render function in summary below
          });
          // Append data
          projectModal.innerHTML = request.responseText;
          document.body.classList.add("modal-project-open");
          // animate modal in
          gsap.set(projectModal, { autoAlpha: 1 });
          gsap.to("#modal-project", {
            yPercent: 0,
            duration: self.modalAnimationDuration
              ? self.modalAnimationDuration
              : 0.5,
            ease: "Sine.easeInOut",
          });

          // update lazyload
          window.lazyLoading.init();

          // reinit scrollmagic
          window.setAnimationScenes(new ScrollMagic.Controller());

          // init modal videos
          window.player.initPlyrs(self.projectModal);

          window.player.addScrollEvents(self.projectModal, "#modal-project");

          window.player.autoPlayVideos(self.projectModal);

          // init modal sliders
          window.sliderGallery = new SliderGallery();

          window.sharePostLinks.init();

          self.preventAjaxForModalLinks(self.projectModal);
        } catch (error) {
          console.log(error);
        }
        // Unlock load more button
        setTimeout(() => {
          window.loading = false;
        }, 1000);
      }
    };
  }
}

export default Grid;
