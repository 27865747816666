class ModalHubspot {
  constructor() {
    const leadinModalContent = document.querySelector(
      ".leadinModal-theme-default"
    );
    const leadinModalClose = document.querySelector(".leadinModal-close");
    function closeModal() {
      leadinModalContent.style.display = "none";
    }
    if (leadinModalContent) {
      leadinModalClose.addEventListener("click", closeModal);
    }
  }
}

export default ModalHubspot;
