// import Swiper JS
import Swiper, { Navigation } from "swiper";

class SliderGallery {
  constructor() {
    this.sliderGallerySections = document.querySelectorAll(
      ".slider-gallery .swiper-container"
    );
    if (this.sliderGallerySections[0]) {
      this.init();
    }
  }

  /* eslint-disable class-methods-use-this, no-unused-vars */
  init() {
    Array.from(this.sliderGallerySections).forEach((section) =>
      this.initSlider(section)
    );
  }

  initSlider(section) {
    const swiperButtonPrev = section.querySelector(".swiper-prev");
    const swiperButtonNext = section.querySelector(".swiper-next");
    const currentSlidesPerView = section.querySelector(".two-images-slider")
      ? 2
      : "auto";
    this.swiperInstance = new Swiper(section, {
      // Optional parameters
      direction: "horizontal",
      loop: false,
      slidesPerView: 1,
      spaceBetween: 16,
      autoHeight: true,
      modules: [Navigation],
      // Navigation arrows
      navigation: {
        nextEl: swiperButtonNext,
        prevEl: swiperButtonPrev,
      },
      breakpoints: {
        992: {
          slidesPerView: currentSlidesPerView,
          spaceBetween: 0,
          freeMode: true,
          autoHeight: false,
        },
      },
    });
  }
  /* eslint-enable class-methods-use-this, no-unused-vars */
}

export default SliderGallery;
