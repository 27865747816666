/* eslint-disable no-undef, class-methods-use-this, prefer-destructuring, no-param-reassign */

import Forms from "../../lib/forms";

class ContactForm extends Forms {
  init() {
    this.firstForm = document.getElementById("contact-form");
    if (this.firstForm) {
      this.newForm(this.firstForm);
    }
  }
}

export default ContactForm;
