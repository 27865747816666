/* eslint-disable */

class Tags {
  constructor() {
    this.init();
  }

  init() {
    const tags = document.querySelectorAll(".js-filtering-tag");
    const self = this;
    if (tags) {
      tags.forEach((el) => {
        el.addEventListener("click", function (ev) {
          window.scrollTo({ top: 0 });

          ev.preventDefault();
          const currentFilter = document.querySelector(
            '.js-filters[data-term="' + el.getAttribute("data-filter") + '"]'
          );
          self.simulateClick(currentFilter);
        });
      });
    }
  }

  simulateClick(elem) {
    // Create our event (with options)
    var evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
  }
}

export default Tags;
