/* eslint-disable */
import Plyr from "plyr";
// import ScrollMagic from "scrollmagic";

class Player {
	initPlyrs(currentSection) {
		// Mobile class & attributes updates
		if (window.isMobile) {
			const autoplayVids = document.querySelectorAll(".js-autoplay");
			if (autoplayVids) {
				autoplayVids.forEach((vid) => {
					if (!vid.classList.contains("js-mobile-autoplay")) {
						vid.classList.remove("js-autoplay");
						vid.classList.add("js-player");
					}
				});
			}
		}

		// Ini videos with custom player
		const plyrEls = currentSection.querySelectorAll(".js-player");
		if (plyrEls) {
			plyrEls.forEach(function (plyr) {
				const player = new Plyr(plyr);
			});
		}

		window.onEvent("body", "click", ".js-audio-icon", function (e) {
			const currentVideo = this.parentNode.querySelector(".bg-video");
			if (currentVideo.muted) {
				currentVideo.muted = false;
				this.parentNode
					.querySelector(".audio-unmute")
					.classList.remove("active");
				this.parentNode.querySelector(".audio-mute").classList.add("active");
			} else {
				currentVideo.muted = true;
				this.parentNode.querySelector(".audio-mute").classList.remove("active");
				this.parentNode.querySelector(".audio-unmute").classList.add("active");
			}
		});
	}

	playVideo(vid) {
		if (vid.classList.contains("js-player")) {
			vid.play();
		}
		if (vid.paused) {
			const pausePromise = vid.play();
			if (pausePromise !== undefined) {
				pausePromise.then((_) => {}).catch((error) => {});
			}
		}
	}

	pauseVideo(vid) {
		if (vid.classList.contains("js-player")) {
			vid.pause();
		}
		if (!vid.paused) {
			const pausePromise = vid.pause();
			if (pausePromise !== undefined) {
				pausePromise.then((_) => {}).catch((error) => {});
			}
		}
	}

	pauseVideos(currentSection = null) {
		const vids = currentSection
			? currentSection.querySelectorAll("video")
			: document.querySelectorAll("video");
		if (vids) {
			vids.forEach((vid) => {
				if (vid.classList.contains("js-player")) {
					vid.pause();
				}
				if (!vid.paused) {
					const pausePromise = vid.pause();
					vid.classList.remove("playing");
					vid.classList.add("disabled");
					if (pausePromise !== undefined) {
						pausePromise.then((_) => {}).catch((error) => {});
					}
				}
			});
		}
	}

	autoPlayVideos(currentSection) {
		const vids = currentSection.querySelectorAll(".js-autoplay");

		if (vids) {
			vids.forEach((vid) => {
				// }
				if (
					!window.isMobile &&
					vid.paused &&
					vid.classList.contains("js-autoplay")
				) {
					const playPromise = vid.play();

					if (playPromise !== undefined) {
						playPromise.then((_) => {}).catch((error) => {});
						vid.classList.add("playing");
						vid.classList.remove("disabled");
					}
				}

				if (
					window.isMobile &&
					vid.paused &&
					vid.classList.contains("js-mobile-autoplay")
				) {
					const playPromise = vid.play();
					if (playPromise !== undefined) {
						playPromise.then((_) => {}).catch((error) => {});
						vid.classList.add("playing");
						vid.classList.remove("disabled");
					}
				}
			});
		}
	}

	addScrollEvents(currentSection = null, scrollContainer = null) {
		/* TODO: Add intersection observer pollyfill */
		const observerOptions = {
			root: null,
			rootMargin: "0px",
			threshold: 0.5,
		};
		const observerCallback = (entries) => {
			entries.forEach((entry) => {
				// if (entry.target.id == "myvideo") {
				if (
					entry.isIntersecting &&
					!entry.target.classList.contains("playing") &&
					!entry.target.classList.contains("disabled")
				) {
					const playPromise = entry.target.play();
					if (playPromise !== undefined) {
						playPromise.then((_) => {}).catch((error) => {});
					}
					entry.target.classList.add("playing");
				}
				if (
					!entry.isIntersecting &&
					entry.target.classList.contains("playing")
				) {
					const pausePromise = entry.target.pause();
					if (pausePromise !== undefined) {
						pausePromise.then((_) => {}).catch((error) => {});
					}
					entry.target.classList.remove("playing");
				}
				// }
			});
		};

		window.observer = new IntersectionObserver(
			observerCallback,
			observerOptions
		);
		let videosToObserve;

		if (!window.isMobile) {
			if (currentSection) {
				videosToObserve = currentSection.querySelectorAll(".js-autoplay");
			} else {
				videosToObserve = document.querySelectorAll(".js-autoplay");
			}
		}
		if (window.isMobile) {
			if (currentSection) {
				videosToObserve = currentSection.querySelectorAll(
					".js-mobile-autoplay"
				);
			} else {
				videosToObserve = document.querySelectorAll(".js-mobile-autoplay");
			}
		}
		if (videosToObserve) {
			videosToObserve.forEach((videoToObserve) => {
				window.observer.observe(videoToObserve);
			});
		}
	}
}
export default Player;
