/* eslint-disable */
import { gsap } from "gsap";
import { Flip } from "gsap/Flip.js"; // eslint-disable-line
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(Flip);
gsap.registerPlugin(ScrollTrigger);
class Header {
  constructor() {
    this.initHeader();
  }

  initHeader() {
    const header = document.getElementById("header");
    const headerHeight = header.offsetHeight;
    const headerTriggerStart = `${headerHeight}px top`;
    const miniLogo = document.querySelector(".mini-logo");
    const toggler = document.querySelector(".header__menu__toggler");
    const menuContainer = document.querySelector(".header__menu-container");

    if (toggler) {
      const navTogglerClosedLabel = toggler.getAttribute("data-closed-label");

      window.menuReset = function () {
        gsap.set(".header__menu-container", {
          y: "-150%",
        });
        toggler.innerHTML = navTogglerClosedLabel;
      };

      // Menu button event listener
      const mobileMenuToogler = document.getElementById("main-menu-toggler");
      if (mobileMenuToogler) {
        mobileMenuToogler.addEventListener("click", toggleNav);
      }
    }

    if (miniLogo) {
      gsap.set(miniLogo, { autoAlpha: 0 });
    }

    const actionNav = gsap.to("#header", {
      yPercent: -150,
      // duration: 0.5,
      ease: "power1.inOut",
      paused: true,
    });

    const actionMiniLogo = gsap.to(miniLogo, {
      autoAlpha: 1,
      duration: 0.25,
      ease: "linear",
      paused: true,
    });

    ScrollTrigger.saveStyles("#header");

    ScrollTrigger.matchMedia({
      // large
      "(min-width: 992px)": function () {
        ScrollTrigger.create({
          trigger: "#header",
          start: headerTriggerStart,
          endTrigger: "#footer",
          onUpdate: ({ direction }) => {
            if (direction === -1) {
              actionNav.reverse();
              actionMiniLogo.reverse();
            }
            if (direction === 1) {
              actionNav.play();
              actionMiniLogo.play();
            }
          },
          onLeaveBack: () => {
            actionNav.reverse();
          },
        });
      },
    });

    // Scroll event listener
    // window.addEventListener("scroll", throttle(callback, 200));

    // Mini logo animation
    const firstBall = document.querySelector(".logo-ball-1");
    const secondBall = document.querySelector(".logo-ball-2");
    let isScrolling = false;
    if (miniLogo) {
      // Init Timeline
      const logoFlipTl = gsap.timeline({
        pause: true,
        repeat: -1,
        scrollTrigger: {
          onUpdate: () => {
            isScrolling = true;
            // play Tl if scroll is in progress and anim not playing yet
            if (!logoFlipTl.isActive()) {
              logoFlipTl.resume();
            }
          },
        },
      });

      // Add first ball animation to timeline
      logoFlipTl.fromTo(
        firstBall,
        {
          yPercent: 0,
        },
        {
          yPercent: 170,
          duration: 1,
          ease: "power1.inOut",
          onComplete: () => {
            // Pause tl if not scrolling and animation is at end of cycle
            if (!isScrolling) {
              logoFlipTl.pause();
            }
          },
        },
        0
      );

      // Add second ball animation to timeline
      logoFlipTl.fromTo(
        secondBall,
        {
          yPercent: 170,
        },
        {
          yPercent: 0,
          duration: 1,
          ease: "power1.inOut",
        },
        0
      );

      // Set isScrolling to false when scrolling stopped
      ScrollTrigger.addEventListener("scrollEnd", () => {
        isScrolling = false;
      });
    }

    function toggleNav() {
      const body = document.body;
      if (!this.classList.contains("menu-item-has-children")) {
        if (body.classList.contains("u-mobile-block-scroll")) {
          toggler.innerHTML = navTogglerClosedLabel;
          gsap.to(menuContainer, {
            y: "-150%",
            duration: 0.6,
            ease: "power2.inOut",
          });

          document.body.classList.remove("u-mobile-block-scroll");
        } else {
          const navTogglerOpenedLabel =
            toggler.getAttribute("data-opened-label");
          toggler.innerHTML = navTogglerOpenedLabel;
          gsap.fromTo(
            menuContainer,
            {
              y: "-100%",
            },
            {
              y: 0,
              duration: 0.6,
              ease: "power1.inOut",
            }
          );
          gsap.fromTo(
            menuContainer.querySelectorAll("li"),
            {
              autoAlpha: 0,
            },
            {
              autoAlpha: 1,
              duration: 0.3,
              ease: "power1.inOut",
              stagger: 0.15,
              delay: 0.2,
            }
          );
          document.body.classList.add("u-mobile-block-scroll");
        }
      }
    }

    // Set a CSS variable to fix Webkit-based mobile phones handling of vh units.
    // You can use it with the vh() SASS function.
    const inSpeed = document
      .querySelector(".barba-container")
      .getAttribute("data-in-speed");

    function setCSSvh() {
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty(
        "--vh",
        `${(window.innerHeight - headerHeight) * 0.01}px`
      );
      document.documentElement.style.setProperty(
        "--fvh",
        `${window.innerHeight * 0.01}px`
      );
      gsap.to(".barba-container", { opacity: 1, duration: inSpeed });
    }

    if (!window.isMobile && window.innerWidth > 992) {
      window.addEventListener("resize", setCSSvh);
    }
    window.addEventListener("orientationchange", setCSSvh);
    setCSSvh();
  }
}

export default Header;
