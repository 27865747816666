import { gsap } from "gsap";

class FeaturedList {
  constructor(list) {
    if (list) {
      const maskers = list.querySelectorAll(".js-masker");
      const texts = list.querySelectorAll(".js-title");
      const descriptions = list.querySelectorAll(".js-description");
      if (maskers && texts) {
        gsap.set(maskers, { scaleY: 0.02, transformOrigin: "left bottom" });
        gsap.set(texts, { x: 0 });
        gsap.set(descriptions, { autoAlpha: 0 });
      }

      const listItems = list.querySelectorAll(".js-list-item");
      if (listItems) {
        listItems.forEach((el) => {
          const animDuration = el.getAttribute("data-anim-speed");
          const rawPower = el.getAttribute("data-anim-power");
          const rawBalance = el.getAttribute("data-anim-balance");
          let animPower;
          let animBalance;

          switch (rawPower) {
            case "low":
              animPower = "power1";
              break;
            case "medium":
              animPower = "power2";
              break;
            case "high":
              animPower = "power3";
              break;
            case "higher":
              animPower = "power4";
              break;
            default:
              animPower = "power1";
              break;
          }

          switch (rawBalance) {
            case "in":
              animBalance = "in";
              break;
            case "out":
              animBalance = "out";
              break;
            case "inout":
              animBalance = "inOut";
              break;
            default:
              animBalance = "inOut";
              break;
          }

          const scaleEasing = animPower + "." + animBalance; // eslint-disable-line
          const currentList = gsap.utils.selector(el);
          const currentMasker = currentList(".js-masker");
          const currentDescription = currentList(".js-description");

          const listItemTl = gsap
            .timeline({
              paused: true,
            })
            .to(
              currentMasker,
              {
                scaleY: 1,
                ease: scaleEasing,
                duration: animDuration,
              },
              0
            );
          if (
            currentDescription &&
            window.matchMedia("(min-width: 992px)").matches
          ) {
            listItemTl.to(
              currentDescription,
              {
                autoAlpha: 1,
                ease: "linear",
                duration: animDuration,
              },
              0.1
            );
          }

          el.addEventListener("mouseenter", function playCurrentTimeline() {
            listItemTl.restart();
          });

          el.addEventListener("mouseleave", function resetCurrentTimeline() {
            listItemTl.reverse();
          });
        });
      }
    }
  }
}

export default FeaturedList;
