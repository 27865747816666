/* eslint-disable */

export const gtmService = (name, more = () => true) => ({
  key: "analytics",
  type: "api",
  name: name, // eslint-disable-line
  uri: "",
  cookies: [],
  init: () => {},
  accept: () => {
    window.cookiesConsent.analytics_storage = 'granted';
    more();
  },
  deny: () => {
    window.cookiesConsent.analytics_storage = 'denied';
    more();
  },
  refuse: () => true,
});

export default gtmService;
