/* eslint-disable no-undef, class-methods-use-this, prefer-destructuring, no-param-reassign */

import { gsap } from "gsap";

class AI {
  constructor() {
    this.ai = document.getElementsByClassName("ai")[0];

    if (this.ai) {
      this.init();
    }
  }

  init() {
    const chatForm = document.getElementById("chat-form");
    const chatInput = document.getElementById("chat-input");
    const chatOutput = document.getElementById("chat-output");
    const chatNotification =
      document.getElementsByClassName("chat-notification")[0];

    this.modelsSelector();

    chatForm.addEventListener("submit", async (e) => {
      e.preventDefault();

      const prompt = chatInput.value;

      if (!prompt) return;

      chatOutput.innerHTML += `<p class="user-message">${prompt}</p>`;
      chatInput.value = "";
      chatOutput.scrollTop = chatOutput.scrollHeight;

      // Clean previous error
      chatNotification.innerHTML = "";

      // Get data
      const data = await this.callAPI(prompt);

      // Show the response or an error
      if (data) {
        const selectedModel =
          document.querySelectorAll(".js-active-label")[0].textContent;
        let response = "";

        switch (selectedModel) {
          case "Dall-e":
            if (data.data[0].url) {
              response = `<a href="${data.data[0].url}" class="bot-link" target="_blank">Open link</a>`;
            } else {
              chatNotification.innerHTML =
                "Error communicating with OpenAI API";
            }
            break;
          default:
            if (data.choices && data.choices[0] && data.choices[0].message) {
              response = data.choices[0].message.content;
            } else {
              chatNotification.innerHTML =
                "Error communicating with OpenAI API";
            }
            break;
        }

        chatOutput.innerHTML += `<p class="bot-message">${response}</p>`;
      } else {
        chatNotification.innerHTML = "Error: Unexpected response format";
      }

      // Scroll the output
      chatOutput.scrollTop = chatOutput.scrollHeight;
    });
  }

  modelsSelector() {
    this.modelsWrapper = document.getElementById("models");
    this.modelsButtons = document.getElementsByClassName("js-models");
    this.modelsButtonsAll = document.querySelectorAll(".js-models");
    this.listToggles = document.getElementsByClassName("js-list-toggle");
    this.modals = document.querySelectorAll(".js-list-content");
    this.modelLabel = "";

    if (this.modelsButtons[0]) {
      const self = this;
      const removeElementsByClass = this.removeElementsByClass;
      const removeHtmlClass = this.removeAllHtmlClass;
      const modals = this.modals;
      const modelsWrapper = this.modelsWrapper;
      const models = this.modelsButtonsAll;
      const collapsibles = modelsWrapper.querySelector(".collapsibles-wrapper");
      const ordermodelList = modelsWrapper.querySelectorAll(".js-ordering")[0];

      modelsWrapper
        .querySelector(".close-filters")
        .addEventListener("click", () => {
          if (collapsibles) {
            collapsibles.classList.remove("active");
            document.body.classList.remove("active-filters");
            gsap.to(collapsibles, {
              x: "110%",
              ease: "Power1.inout",
            });
          }
        });

      modelsWrapper
        .querySelector(".open-models")
        .addEventListener("click", () => {
          if (collapsibles) {
            collapsibles.classList.add("active");
            document.body.classList.add("active-filters");

            gsap.to(collapsibles, {
              x: 0,
              ease: "Power1.inout",
            });
          }
        });

      Array.prototype.forEach.call(this.listToggles, (listToggle) => {
        listToggle.addEventListener("click", (event) => {
          event.preventDefault();
          const currentModal = listToggle
            .closest(".js-model-wrapper")
            .querySelector(".js-list-content");
          const modalIsActive = currentModal.classList.contains("is-current");
          removeHtmlClass(modals, "is-current");

          if (!modalIsActive) {
            currentModal.classList.add("is-current");
          }
          self.modelLabel = listToggle.querySelector(".js-active-label");
        });
      });

      Array.prototype.forEach.call(this.modelsButtons, (modelButton) => {
        modelButton.addEventListener("click", (event) => {
          event.preventDefault();

          const modelIsActive = modelButton.classList.contains("is-active");
          const isOrdeningmodel = modelButton.classList.contains("js-order");
          const isReset = modelButton.classList.contains("js-reset");
          const activemodels =
            modelsWrapper.getElementsByClassName("is-active");

          // Default models dataset
          let taxonomy = "";
          let term = "";
          let orderby = "newest";

          const orderbyLabel = modelButton.querySelector(".js-label")
            ? modelButton.querySelector(".js-label").innerHTML
            : "";

          if (!modelIsActive) {
            if (isReset) {
              removeHtmlClass(models, "is-active");
              ordermodelList
                .getElementsByClassName("js-order")[0]
                .classList.add("is-active");
            } else if (isOrdeningmodel) {
              removeHtmlClass(models, "is-active", "js-model");
              modelsWrapper.setAttribute(
                "data-orderby",
                modelButton.dataset.orderby
              );
            } else {
              removeHtmlClass(models, "is-active", "js-order");
            }

            // Add class to current button
            modelButton.classList.add("is-active");

            // models dataset
            Array.prototype.forEach.call(activemodels, (model) => {
              taxonomy = model.dataset.taxonomy
                ? model.dataset.taxonomy
                : taxonomy;
              term = model.dataset.term ? model.dataset.term : term;
              orderby = model.dataset.orderby ? model.dataset.orderby : orderby;
            });

            this.selectModel(
              modelButton,
              removeElementsByClass,
              removeHtmlClass,
              modals,
              self,
              orderbyLabel,
              isReset
            );
          }
        });
      });
    }
  }

  selectModel(
    element,
    removeElementsByClass,
    removeHtmlClass,
    modals,
    self,
    orderbyLabel,
    isReset
  ) {
    try {
      const menuLabels = document.querySelectorAll(".js-active-label");
      const currentLabel = self.modelLabel;
      const currentLableParent =
        currentLabel.parentNode.parentNode.parentNode.parentNode;

      menuLabels.forEach((menuLabel) => {
        const preventResetLabel = menuLabel.dataset.preventReset
          ? menuLabel.dataset.preventReset
          : "";
        let resetLabel = menuLabel.dataset.reset;

        if (
          (preventResetLabel === "check" && !isReset) ||
          (preventResetLabel === "check" &&
            currentLableParent.classList.contains("sorting") === true)
        ) {
          resetLabel =
            menuLabel.parentNode.parentNode.parentNode.parentNode.getElementsByClassName(
              "is-active"
            )[0].textContent;
        }

        if (
          isReset ||
          currentLableParent.classList.contains("sorting") === false
        ) {
          menuLabel.innerHTML = resetLabel; // eslint-disable-line
        }
      });

      if (currentLabel && !isReset) {
        currentLabel.innerHTML = orderbyLabel;
      }

      removeHtmlClass(modals, "is-current");

      document
        .getElementById("models")
        .querySelector(".collapsibles-wrapper")
        .classList.remove("active");
      document.body.classList.remove("active-models");

      if (
        document.querySelector(".collapsibles-wrapper") &&
        window.matchMedia("(max-width: 991px)").matches
      ) {
        gsap.to(document.querySelector(".collapsibles-wrapper"), {
          x: "110%",
        });
      }
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  }

  removeElementsByClass(element, className) {
    const elements = element.getElementsByClassName(className);

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  removeAllHtmlClass(elements, className, exceptions = "") {
    elements.forEach((element) => {
      if (element.classList.contains(exceptions) === false) {
        element.classList.remove(className);
      }
    });
  }

  callAPI(input) {
    return new Promise((resolve, reject) => {
      const models = document.querySelectorAll(".js-active-label");

      Array.prototype.forEach.call(models, (model) => {
        let action = "";
        const formData = new FormData();
        let data;

        switch (model.textContent) {
          case "Dall-e":
            action = "dalle";
            break;
          default:
            action = "chatgpt";
            break;
        }

        formData.append("action", action);
        formData.append("input", JSON.stringify(input));
        formData.append("nonce", scriptsAjaxVariables.nonce);

        // Ajax form submit
        const xmlhttp = window.ajaxReq();
        const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
        xmlhttp.open("POST", url, true); // set true for async, false for sync request
        xmlhttp.send(formData); // or null, if no parameters are passed

        // Validation
        xmlhttp.onreadystatechange = () => {
          if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
            try {
              data = JSON.parse(xmlhttp.responseText);
              resolve(data);
            } catch (error) {
              reject(error);
            }
          }
        };
      });
    });
  }
}

export default AI;
